export default function ssnValidator(input: string) {
  if (!input) return false
  const value = input.replace(/[^a-zA-z0-9]+/g, '')
  // http://rion.io/2013/09/10/validating-social-security-numbers-through-regular-expressions-2/
  if (
    !/^(?!219099999|078051120|123456789)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(
      value,
    )
  ) {
    return false
  }
  return true
}
