











































































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { mask } from 'vue-the-mask'
import { requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { validationStateMixin } from '@/util/validationState'
import ssnValidator from '@/util/validators/ssnValidator'
import Modal from '@/components/Modal.vue'
import USStateInput from '@/components/inputs/USStateInput.vue'
import UserIdentification from '@/models/UserIdentification'
import { UserIdentificationModalInfo } from '@/vuex/user'

interface License {
  state?: string
  number?: string
}

function hasSsn(this: AddUserIdentificationModal) {
  return !this.noSsn
}

function hasLicense(this: AddUserIdentificationModal) {
  return this.noSsn && !this.noLicense
}

@Component({
  components: {
    Modal,
    USStateInput,
  },
  mixins: [validationMixin, validationStateMixin],
  directives: {
    mask,
  },
  validations: {
    ssn: {
      required: requiredIf(hasSsn),
      ssnValidator(this: AddUserIdentificationModal, input: string) {
        return this.noSsn ? true : ssnValidator(input)
      },
    },
    license: {
      state: {
        required: requiredIf(hasLicense),
      },
      number: {
        required: requiredIf(hasLicense),
      },
    },
  },
  computed: {
    ...mapState('user', ['userIdentificationModal']),
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded']),
  },
})
export default class AddUserIdentificationModal extends Vue {
  readonly hasSucceeded!: (key: string) => boolean
  readonly isInProgress!: (key: string) => boolean
  readonly userIdentificationModal!: UserIdentificationModalInfo

  ssn: string = ''
  noSsn: boolean = false
  noLicense: boolean = false
  license: License = {
    state: undefined,
    number: undefined,
  }

  get userId(): string | undefined {
    if (!this.noSsn) return this.ssn
    if (!this.noLicense) return this.license.number
    return undefined
  }

  get isLoading(): boolean {
    return this.isInProgress('user/updateUserIdentification')
  }

  get invalidLicenseMessage(): TranslateResult | null {
    if (!this.noLicense && this.$v.license && this.$v.license.number) {
      return this.$t('Please enter your license number')
    }
    return null
  }

  get shouldConfirmBeforeClose(): boolean {
    return this.hasSucceeded('user/updateUserIdentification') ? false : !!this.$v.$anyDirty
  }

  onSubmit() {
    this.$v.$touch()

    if (!this.$v.$invalid) {
      const userIdentification: UserIdentification = {
        id: this.userId,
        jurisdiction: this.license.state,
        noLicense: this.noLicense,
        noSsn: this.noSsn,
        type: undefined,
      }
      this.submit(userIdentification)
    }
  }

  async submit(userIdentification: UserIdentification): Promise<void> {
    await this.$store.dispatch('user/updateUserIdentification', userIdentification)

    if (this.hasSucceeded('user/updateUserIdentification')) {
      this.$bvToast.toast(this.$t('Your information has been saved') as string, {
        title: this.$t('Information saved') as string,
        variant: 'success',
      })
      this.complete()
      this.close()
    }
  }

  complete() {
    if (this.userIdentificationModal.onComplete) {
      this.userIdentificationModal.onComplete()
    }
  }

  reset() {
    this.ssn = ''
    this.noSsn = false
    this.noLicense = false
    this.license = {
      state: undefined,
      number: undefined,
    }
    this.$v.$reset()
  }

  close() {
    this.$store.dispatch('user/setUserIdentificationModal', { visible: false })
    this.reset()
  }
}
